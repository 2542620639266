<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.friendEntId" filterable clearable url="api/distributor" placeholder="选择经销商" value-field="enterpriseId" @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="返利日期起始" end-placeholder="返利日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-select v-model="query.inOutType" @change="toQuery" clearable placeholder="方向" style="width: 100px; margin-right: 6px;">
        <el-option value="in" label="收入" />
        <el-option value="out" label="支出" />
      </el-select>
      <quick-select v-model="query.projectId" filterable clearable :url="query.inOutType === 'in' ? 'api/payProject/0' : 'api/payProject/1'" placeholder="记账类型" @change="toQuery" class="filter-item" style="width: 180px;" v-if="query.inOutType" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button-group class="filter-item">
        <el-button type="primary" icon="el-icon-plus" @click="add('in', 1)">新建收款单</el-button>
        <el-button type="primary" @click="add('out', 1)">新建退款单</el-button>
        <el-button type="primary" @click="add('out', 2)">新建提现单</el-button>
      </el-button-group>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="code" label="记账单号" width="130" fixed />
      <el-table-column prop="erpCode" label="ERP单号" width="130" fixed />
      <el-table-column prop="friendEntName" label="经销商" min-width="190" show-overflow-tooltip />
      <el-table-column label="方向" width="80">
        <template slot-scope="scope">{{scope.row.inOutType === "in" ? "收入" : "支出"}}</template>
      </el-table-column>
      <el-table-column prop="projectName" label="记账类型" width="120" />
      <el-table-column label="金额" align="right" width="120">
        <template slot-scope="scope">
          <b :class="scope.row.payMoney < 0 ? 'fc-e' : ''">{{$price(scope.row.payMoney)}}</b>
        </template>
      </el-table-column>
      <el-table-column label="登记时间" width="150" :formatter="r => {return new Date(r.payTime).format();}" />
      <el-table-column label="支付方式" width="80" :formatter="r => {return paymentMethods[r.payType];}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="200" />
      <el-table-column label="状态" width="80" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="detail(scope.row, false)">{{scope.row.status === 0 ? "编辑" : "查看"}}</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row, true)" v-if="scope.row.status === 0 || scope.row.status === 3">审批</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      paymentMethods: ["在线支付", "线下支付"],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "primary",
          label: "已支付",
        },
      ],
      query: {
        friendEntId: null,
        dateRange: null,
        inOutType: null,
        projectId: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/payForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      query.relationFormTypes = [1, 2, 3];
      delete query.dateRange;
      query.sort = "createAt,asc";
      this.params = query;
      return true;
    },
    add(inout, formType) {
      this.$refs.form &&
        this.$refs.form.resetForm(null, false, {
          inOutType: inout,
          relationFormType: formType,
        });
    },
    detail(row, checkable = false) {
      this.$refs.form && this.$refs.form.resetForm(row, checkable);
    },
  },
  mounted() {
    this.init();
  },
};
</script>