<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="700px" @closed="handleClosed">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <el-form-item prop="friendEntId" label="往来单位">
            <quick-select v-model="form.friendEntId" filterable :label.sync="form.friendEntName" value-field="enterpriseId" url="api/distributor" auto-select-first-option placeholder="请选择往来单位" @change="loadBalance" style="width: 230px;" />
          </el-form-item>
          <template v-if="form.inOutType === 'in' && form.relationFormType === 1">
            <div class="h">
              <el-form-item prop="projectId" label="记账类型">
                <quick-select v-model="form.projectId" filterable url="api/payProject/0" :label.sync="form.projectName" auto-select-first-option placeholder="请选择记账类型" style="width: 230px;" />
              </el-form-item>
              <el-form-item label="收款方式" label-width="116px">
                <el-radio-group v-model="form.payType">
                  <el-radio-button :label="0">在线支付</el-radio-button>
                  <el-radio-button :label="1">线下支付</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="h">
              <el-form-item prop="payMoney" label="收款金额">
                <price-input v-model="form.payMoney" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
              </el-form-item>
              <el-form-item prop="payTime" label="收款日期" label-width="116px">
                <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
              </el-form-item>
            </div>
            <el-form-item key="inpay" prop="payerAccount" label="支付账户">
              <div class="h">
                <el-input v-model="form.payerAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.friendEntId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" v-if="form.friendEntId" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item key="inrec" prop="payeeAccount" label="收款账户">
              <div class="h">
                <el-input v-model="form.payeeAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
          </template>
          <template v-else-if="form.inOutType === 'out'">
            <template v-if="form.relationFormType === 1">
              <el-form-item prop="projectId" label="记账类型">
                <quick-select v-model="form.projectId" filterable url="api/payProject/1" :label.sync="form.projectName" auto-select-first-option placeholder="请选择记账类型" style="width: 230px;" />
              </el-form-item>
              <div class="h">
                <el-form-item prop="payMoney" label="退款金额">
                  <price-input v-model="form.payMoney" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
                </el-form-item>
                <el-form-item prop="payTime" label="退款日期" label-width="116px">
                  <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
                </el-form-item>
              </div>
              <el-form-item key="outpay" prop="payerAccount" label="退款账户">
                <div class="h">
                  <el-input v-model="form.payerAccount" maxlength="80" clearable class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" v-if="form.friendEntId" style="margin-left: 6px; margin-top: -1px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
              <el-form-item key="outrec" prop="payeeAccount" label="收款账户">
                <div class="h">
                  <el-input v-model="form.payeeAccount" maxlength="80" clearable class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.friendEntId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 6px; margin-top: -1px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
            </template>
            <template v-else-if="form.relationFormType === 2">
              <el-form-item prop="payMoney" label="提现金额">
                <div class="h c">
                  <price-input v-model="form.payMoney" :min="0" :max="maxBalance / 100" :step-ratio="10000" style="width: 230px" />
                  <div class="padding-0-10">最大可提现金额：{{$price(maxBalance)}}</div>
                </div>
              </el-form-item>
              <el-form-item prop="payTime" label="提现日期">
                <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
              </el-form-item>
            </template>
          </template>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" :loading="saving" @click="doSubmit">提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="66px" label-position="right" label-suffix=":">
          <div class="h s sb">
            <div style="width: 50%; margin-right: 30px;">
              <form-info-item label="往来单位">{{form.friendEntName}}</form-info-item>
              <template v-if="form.inOutType === 'in' && form.relationFormType === 1">
                <form-info-item label="记账类型">{{form.projectName}}</form-info-item>
                <form-info-item label="收款方式">{{paymentMethods[form.payType]}}</form-info-item>
                <form-info-item label="收款金额" inline>
                  <b class="fs-large fc-s">{{$price(form.payMoney)}}</b>
                </form-info-item>
                <form-info-item label="收款日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
                <form-info-item label="支付账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
                <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
              </template>
              <template v-else-if="form.inOutType === 'out'">
                <template v-if="form.relationFormType === 1">
                  <form-info-item label="记账类型">{{form.projectName}}</form-info-item>
                  <form-info-item label="退款金额" inline>
                    <b class="fs-large fc-e">{{$price(form.payMoney)}}</b>
                  </form-info-item>
                  <form-info-item label="退款日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
                  <form-info-item label="退款账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
                  <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
                </template>
                <template v-else-if="form.relationFormType === 2">
                  <form-info-item label="提现金额" inline>
                    <b class="fs-large fc-e">{{$price(form.payMoney)}}</b>
                  </form-info-item>
                  <form-info-item label="提现日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
                </template>
              </template>
            </div>
            <div class="flex">
              <form-info-item label="记账单号">{{form.code}}</form-info-item>
              <form-info-item label="ERP单号" v-if="form.erpCode !== form.code">{{form.erpCode}}</form-info-item>
              <form-info-item label="创建人">{{form.createBy}}</form-info-item>
              <form-info-item label="创建时间">{{new Date(form.createAt).format()}}</form-info-item>
              <form-info-item label="当前状态">
                <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
              </form-info-item>
              <template v-if="form.status === 1 || form.status === 2">
                <form-info-item label="审批时间">{{new Date(form.checkTime).format()}}</form-info-item>
                <form-info-item label="审批意见">{{form.checkInfo}}</form-info-item>
              </template>
            </div>
          </div>
          <form-info-item label="备注">{{form.info}}</form-info-item>
        </el-form>

        <template v-if="auditable && auditForm">
          <el-divider content-position="left">记账审批</el-divider>
          <el-form ref="form" :model="auditForm" label-width="78px" label-position="right" label-suffix=":">
            <div class="h">
              <el-form-item label="是否通过" style="width: 50%;">
                <el-radio-group v-model="auditForm.pass" @change="handleAuditPassChange">
                  <el-radio-button :label="true">通过</el-radio-button>
                  <el-radio-button :label="false">不通过</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审批日期" class="flex" v-if="auditForm.pass">
                <el-date-picker type="date" v-model="auditForm.checkinTime" value-format="timestamp" :clearable="false" style="width: 100%;" />
              </el-form-item>
            </div>
            <el-form-item label="审批意见">
              <el-input type="textarea" v-model="auditForm.checkInfo" :maxlength="120" :rows="2" resize="none" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="auditing" @click="doAudit">提交</el-button>
              <el-button @click="dialog = false">取消</el-button>
            </el-form-item>
          </el-form>
        </template>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { add, edit, audit } from "@/api/payForm";
import { getByDistributor } from "@/api/capitalPool";

export default {
  data() {
    return {
      dialog: false,
      form: null,
      auditForm: null,
      saving: false,
      auditing: false,
      paymentMethods: ["在线支付", "线下支付"],
      inFormTypes: ["", "收款单", "", ""],
      outFormTypes: ["", "退款单", "提现单", ""],
      paymentAccoutTypes: ["银联", "支付宝", "微信", "银行卡"],
      formTypes: ["银行卡", "支付宝", "微信"],
      auditable: false,
      maxBalance: 0,
      rules: {
        friendEntId: [{ required: true, message: "请选择经销商" }],
        payType: [{ required: true, message: "请选择支付方式" }],
        payTime: [{ required: true, message: "请选择收款日期" }],
        payMoney: [{ required: true, message: "请填写收款金额" }],
        projectId: [{ required: true, message: "请选择记账类型" }]
      },
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "success",
          label: "已确认"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "primary",
          label: "已支付"
        }
      ]
    };
  },
  computed: {
    orderTypeName() {
      let tn = "";
      if (this.form) {
        if (this.form.inOutType === "in") {
          tn = this.inFormTypes[this.form.relationFormType];
        } else {
          tn = this.outFormTypes[this.form.relationFormType];
        }
      }
      return tn;
    },
    title() {
      if (this.form) {
        let tn = this.orderTypeName;
        if (this.editable) {
          return (this.form.id ? "编辑" : "新建") + tn;
        } else if (this.auditable) {
          return "审批" + tn;
        } else {
          return "查看" + tn;
        }
      }
      return "加载中…";
    },
    editable() {
      return this.form && this.form.status === 0 && !this.auditable;
    }
  },
  methods: {
    handleClosed() {
      this.form = null;
    },
    loadBalance() {
      if (this.form && this.form.friendEntId) {
        getByDistributor(this.form.friendEntId).then(res => {
          this.maxBalance = res.canUseBalance || 0;
        });
      }
    },

    handleAuditPassChange() {
      if (this.form && this.auditForm) {
        this.auditForm.checkinTime = this.auditForm.pass
          ? this.form.payTime
          : null;
      }
    },
    doAudit() {
      this.$confirm(
        `确定对该${this.orderTypeName}进行【${
          this.auditForm.pass ? "通过" : "不通过"
        }】审批操作吗？`,
        "操作提示",
        {
          type: "warning"
        }
      ).then(_ => {
        this.auditing = true;
        audit(this.auditForm)
          .then(res => {
            this.$notify({
              title: "审批成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.auditing = false;
          });
      });
    },

    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          let form = JSON.parse(JSON.stringify(this.form));
          if (form.inOutType === "out")
            form.payMoney = Math.abs(form.payMoney) * -1;
          add(form)
            .then(res => {
              this.$notify({
                title: `提交${this.orderTypeName}成功`,
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              this.$parent.init();
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form, auditable = false, extend = null) {
      this.auditable =
        auditable &&
        form &&
        form.id &&
        (form.status === 0 || form.status === 3);
      this.form =
        form ||
        Object.assign(
          {
            friendEntId: null,
            friendEntName: "",
            payMoney: 0,
            payType: 0,
            payTime: new Date().getTime(),
            payerAccount: "",
            payeeAccount: "",
            status: 0,
            projectId: null,
            projectName: "",
            info: ""
          },
          extend || {}
        );
      if (this.auditable) {
        this.auditForm = {
          id: form.id,
          pass: true,
          checkinTime: this.form.payTime,
          checkInfo: ""
        };
      }
      this.dialog = true;
    },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    }
  }
};
</script>